<template>
    <div v-if="verpai">
      <v-btn plain to="/gasto/bodega"><v-icon left>mdi-package-variant</v-icon>Bodega</v-btn>
      <v-btn plain to="/gasto/ordcompra"><v-icon left>mdi-tray-full</v-icon>Órdenes de compra</v-btn>
      <v-btn plain to="/gasto/infsema"><v-icon left>mdi-file-document-multiple-outline</v-icon>Informes SEMA</v-btn>
      <v-btn plain to="/gasto/presupuesto"><v-icon left>mdi-cash-multiple</v-icon>Presupuesto</v-btn>
      <v-btn plain to="/gasto/pesokilo"><v-icon left>mdi-scale-balance</v-icon>$/KG</v-btn>
      <v-divider></v-divider>
      <router-view></router-view>
    </div>
</template>

<script>
export default {
name: "gasto",
computed:{
  verpai: function () {
    let user = JSON.parse(sessionStorage.getItem('user'))
    return user.planta.includes('paico')?true:false
  }
},
methods:{
},
mounted(){
  let user = JSON.parse(sessionStorage.getItem('user'))
  if (!user){
    this.$router.push('/login');
  }
}
}
</script>
